import React from "react";
import { Link } from "react-router-dom";

export default class LogoComponent extends React.Component {
  render() {
    return (
      <Link to={"/"} style={{ textDecoration: "none" }}>
        <div
          id="logo-container"
          style={{
            padding: 30,
            cursor: "pointer",
          }}
        >
          <div
            style={{
              border: "3px solid white",
              borderRadius: 10,
              padding: 10,
            }}
          >
            <div style={{ padding: 5 }}>
              <span
                style={{ color: "white", fontWeight: "bold", fontSize: 30 }}
              >
                expense
              </span>
              <br />
              <span
                style={{ color: "yellow", fontSize: 30, fontWeight: "bold" }}
              >
                hut
              </span>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}
