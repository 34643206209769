import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import LanguageIcon from "@mui/icons-material/Language";
import PaidIcon from "@mui/icons-material/Paid";
import WorkIcon from "@mui/icons-material/Work";
import FeedbackComponent from "../components/other/feedback.component";
import RewardsComponent from "../components/rewards.component";
import Tile from "../components/tile.component";
import { useLayoutContext } from "../providers/layout.provider";

export default function HomePage() {
  const { isMobile } = useLayoutContext();

  return (
    <div className={`mb-160`} id="home-body">
      <RewardsComponent />

      <div className={isMobile ? "divColumn divCenterAlign" : "divCenterAlign"}>
        <Tile
          id="home-page-account-tile"
          title={"Settings"}
          subtitle={""}
          route="/settings"
          iconComp={() => (
            <AccountCircleIcon color="secondary" fontSize="large" />
          )}
        />

        <Tile
          id="home-page-faq-tile"
          title="Support"
          subtitle="Submit your question"
          route="/support"
          iconComp={() => (
            <img
              src={"/assets/icons/question.png"}
              className="iconSizeLarge"
              alt="faq"
            />
          )}
        />
      </div>

      <div className={isMobile ? "divColumn divCenterAlign" : "divCenterAlign"}>
        <Tile
          id="home-page-jobs-tile"
          title="ExpenseHut"
          subtitle={"A personal finance dashboard."}
          external="https://app.expensehut.com"
          iconComp={() => <PaidIcon fontSize="large" color="warning" />}
        />

        <Tile
          id="home-page-company-rating-tile"
          subtitle={"Split bills, receipts between friends or family."}
          title={"Wallet"}
          external="https://wallet.expensehut.com"
          iconComp={() => <CreditCardIcon color="success" fontSize="large" />}
        />
      </div>

      <div className={isMobile ? "divColumn divCenterAlign" : "divCenterAlign"}>
        <Tile
          id="home-page-jobs-tile"
          title="Recruit"
          subtitle={"A next-gen job search platform."}
          external="https://recruit.expensehut.com"
          iconComp={() => <WorkIcon fontSize="large" color="primary" />}
        />

        <Tile
          id="home-page-company-rating-tile"
          subtitle={"Author articles & publish for free!"}
          title={"Blogs"}
          external="https://blogs.expensehut.com"
          iconComp={() => <LanguageIcon color="success" fontSize="large" />}
        />
      </div>

      <FeedbackComponent />
    </div>
  );
}
