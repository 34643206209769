import { Link, Route, Routes, useNavigate } from "react-router-dom";
import RequireAdminAccess from "./higher-order/require-admin-access";
import AdminLandingPage from "./pages/admin/admin-landing.page";
import AdminUsersPage from "./pages/admin/admin-users.page";
import AdminMoneyTransfersPage from "./pages/admin/admin-money-transfers.page";
import AdminMoneyTransfersViewUserPage from "./pages/admin/admin-money-transfers-view.page";
import AdminViewUserPage from "./pages/admin/admin-view-user.page";
import HomePage from "./pages/home.page";
import ProfilePage from "./pages/settings/profile.page";
import ChangePasswordPage from "./pages/settings/security/change-password.page";
import SettingsPage from "./pages/settings/settings.page";
import SupportPage from "./pages/support/support.page";
import SupportViewPage from "./pages/support/support.view.page";
import AuthProvider, { useAuthContext } from "./providers/auth.provider";
import { useGetProfile } from "./api/api.get";
import HomeIcon from "@mui/icons-material/Home";
import { capitalize } from "./utils/functions";
export const AuthenticatedRoutes = () => {
  const adminRoutes = (
    <RequireAdminAccess>
      <Routes>
        <Route path="users/:username" element={<AdminViewUserPage />} />
        <Route path="users" element={<AdminUsersPage />} />
        <Route
          path="money-transfers/:id"
          element={<AdminMoneyTransfersViewUserPage />}
        />
        <Route path="money-transfers" element={<AdminMoneyTransfersPage />} />
        <Route path="" element={<AdminLandingPage />} />
      </Routes>
    </RequireAdminAccess>
  );

  const settingsRoutes = (
    <Routes>
      <Route path="/change-password" element={<ChangePasswordPage />} />
      <Route path="/profile" element={<ProfilePage />} />

      <Route path="/" element={<SettingsPage />} />
    </Routes>
  );

  const supportRoutes = (
    <Routes>
      <Route path="/:id" element={<SupportViewPage />} />
      <Route path="/" element={<SupportPage />} />
    </Routes>
  );

  return (
    <>
      <WelcomePane />
      <Routes>
        <Route path="/home" element={<HomePage />} />
        <Route path="/settings/*" element={settingsRoutes} />
        <Route path="/support/*" element={supportRoutes} />
        <Route path="/admin/*" element={adminRoutes} />
      </Routes>
    </>
  );
};

function WelcomePane() {
  const { data: profile } = useGetProfile();
  return (
    <div className="divRight p-40">
      Welcome {capitalize(profile?.firstName) ?? "Guest"}
    </div>
  );
}
